function Home() {
  return (
    <div className="home">
      <h2>Home</h2>
      <p>
        Pages for Callum Grayson - These show work of all kinds from fun
        projects to useful apps, as well as code examples for many applications
      </p>
    </div>
  );
}

export default Home;
